<template>
	<div class="allNews">
		<!-- <div>{{item.name}}</div> -->
		<div class="perNews">
			<router-link
				target="_blank"
				:to="{ name: '/gw_detail', params: { id: item.id } }"
				style="display: flex"
			>
				<div class="imgPart">
					<img :src="item.pic" :key="item.pic" />
				</div>
				<div class="textPart">
					<div class="mainContent">
						{{
							localLanguage == 2
								? item.title
									? item.title
									: item.title
								: item.title
						}}
					</div>
					<div class="tagBox">
						<img
							class="tagFlag"
							v-if="item.is_top"
							src="https://res.metaera.hk/resources/assets/images/home/set_top.png"
						/>
						<span class="tagName"></span>
						<p class="intoText tagText" v-if="item.author"
							>{{ item.author.nickname }}
						</p>
						<div class="intoText tagText">{{ item.release_time }}</div>
					</div>
				</div>
			</router-link>
		</div>
	</div>
</template>

<script>
import { newBus } from "@/components/pc/topHeader2.vue";

export default {
	props: ["item"],
	data() {
		return {
			localLanguage: 1,
		};
	},
	mounted() {
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
};
</script>

<style scoped>
.allNews {
	/* padding: 0 15px; */
}
.perNews {
	display: flex;
	padding: 12px 0;
	justify-content: space-between;
}
.textPart {
	box-sizing: border-box;
	width: 70%;
	padding-left: 15px;
}
.mainContent {
	font-size: 16px;
	line-height: 22px;
	font-weight: 600;
	color: #fff;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.tagBox {
	display: flex;
	align-items: center;
	margin-top: 4px;
}
.tagFlag {
	width: 17px;
	margin-right: 5px;
}
.tagName {
	margin-right: 5px;
	width: 1px;
	height: 8px;
	background: #b2b2b2;
}
.tagText {
	font-size: 10px;
	line-height: 14px;
	margin-right: 7px;
}
.toTopTag {
	color: #294afd;
}
.flashTagImg {
	display: block;
	width: 12px;
}
.flashTagText {
	color: #9329fd;
}
.intoText {
	color: #a0a0a8;
}
.imgPart {
	width: 30%;
	height: auto;
	/* border-radius: 5px; */
	overflow: hidden;
}
.imgPart img {
	display: block;
	width: 100%;
	height: auto;
	object-fit: cover;
	border-radius: 5px;
}
</style>
